import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { TrackingService } from 'src/app/core/tracking.service';
import { UserpilotService } from 'src/app/shared/userpilot.service';
import { datadogLogs } from '@datadog/browser-logs';
import { ApiService } from 'ngxSpring/api.service';

/**
 * lbox-components를 단순히 렌더링만 시키기 위해서 사용하는 component
 *
 * evnet listener를 추가하면 app.component에서 생성한 event listener와 중복되는 문제가 발생합니다.
 */
@Injectable()
export class LboxComponentsService {
  isServer = typeof window === 'undefined';

  constructor(
    private api: ApiService,
    private userService: UserService,
    private trackingService: TrackingService,
    private userpilotService: UserpilotService,
  ) {}
  importAgreedPlanPricingAlert() {
    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ agreedPlanPricingAlert }) => {
        agreedPlanPricingAlert.mount('agreed-plan-pricing-alert-root');
      })
      .catch((e) => {
        console.error('cannot mount agreed plan pricing alert', e);
      });
  }

  importUnsubscribeStandardPlanModal() {
    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ unsubscribeStandardPlanModal }) => {
        unsubscribeStandardPlanModal.mount(
          'unsubscribe-standard-plan-modal-root',
        );

        window.addEventListener('success-unsubscribe-standard-plan', () => {
          this.api.lboxUser.getLboxUser().subscribe((lboxUserData) => {
            this.userService.updateUser(lboxUserData.user, false);
          });
        });
      })
      .catch((e) => {
        console.error('cannot mount unsubscribe standard plan modal', e);
      });
  }

  removeUnsubscribeStandardPlanModal() {
    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ unsubscribeStandardPlanModal }) => {
        unsubscribeStandardPlanModal.unmount(
          'unsubscribe-standard-plan-modal-root',
        );
      })
      .catch((e) => {
        console.error('cannot unmount unsubscribe standard plan modal', e);
      });
  }

  importPlanPricingAlerts() {
    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ planPricingAlerts }) => {
        planPricingAlerts.mount('plan-pricing-alerts-root');
      })
      .catch((e) => {
        console.error('cannot mount plan pricing alerts', e);
      });
  }

  importPlanPricingPopUp() {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ planPricingPopUp }) => {
        planPricingPopUp.mount('pop-up-root');
      })
      .catch((e) => {
        console.error('cannot mount plan pricing popup', e);
      });
  }

  unmountReferToProvisionRightPanelInMobile() {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ referToProvisionRightPanelInMobile }) => {
        referToProvisionRightPanelInMobile.unmount(
          'refer-to-provision-right-panel-root',
        );
      })
      .catch((e) => {
        console.error('cannot unmount refer to provision right panel', e);
      });
  }

  importReferToProvisionRightPanelInMobile() {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ referToProvisionRightPanelInMobile }) => {
        referToProvisionRightPanelInMobile.mount(
          'refer-to-provision-right-panel-root',
        );
      })
      .catch((e) => {
        console.error('cannot mount refer to provision right panel', e);
      });
  }

  importReferToProvisionRightPanel() {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ referToProvisionRightPanel }) => {
        referToProvisionRightPanel.mount('refer-to-provision-right-panel');
      })
      .catch((e) => {
        console.error('cannot mount refer to provision right panel', e);
      });
  }

  unmountReferToProvisionRightPanel() {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ referToProvisionRightPanel }) => {
        referToProvisionRightPanel.unmount('refer-to-provision-right-panel');
      })
      .catch((e) => {
        console.error('cannot unmount refer to provision right panel', e);
      });
  }

  importSearchByLawfirmCaseRightPanel() {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ searchByLawfirmCaseRightPanel }) => {
        searchByLawfirmCaseRightPanel.mount(
          'search-by-lawfirm-case-right-panel-root',
        );
      })
      .catch((e) => {
        console.error('cannot mount ai relative case right panel', e);
      });
  }

  importAiRelativeCaseRightPanel() {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ aiRelativeCaseRightPanel }) => {
        aiRelativeCaseRightPanel.mount('ai-relative-case-right-panel-root');
      })
      .catch((e) => {
        console.error('cannot mount ai relative case right panel', e);
      });
  }

  importFollowingCaseRightPanel() {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ followingCaseRightPanel }) => {
        followingCaseRightPanel.mount('following-case-right-panel-root');
      })
      .catch((e) => {
        console.error('cannot mount following case right panel', e);
      });
  }

  importHeader({
    isLoggedIn,
    isJobLawyer,
    name,
  }: {
    isLoggedIn: boolean;
    isJobLawyer: boolean;
    name: string;
  }) {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ headerType1 }) => {
        headerType1.mount('header', {
          isLoggedIn,
          isJobLawyer,
          name,
        });

        window.addEventListener(
          'header-event',
          (
            event: CustomEvent<{
              eventType: string;
              eventProperties?: Record<string, unknown>;
            }>,
          ) => {
            void this.trackingService.tracking(event.detail.eventType, {
              keyword: event.detail.eventProperties.keyword,
              category: event.detail.eventProperties.category,
              page: event.detail.eventProperties.page,
              isLeafEvent: true,
              funnel_root_id: this.trackingService.getCasePDPFunnelRootId(),
            });
          },
        );
        window.addEventListener(
          'header-userpilot-trigger-event',
          (event: CustomEvent<{ eventType: string }>) => {
            void this.userpilotService.instance?.trigger(
              event.detail.eventType,
            );
          },
        );
        window.addEventListener(
          'header-error',
          ({
            detail,
          }: CustomEvent<{
            message: string;
            context: Record<string, unknown>;
            error: Error;
          }>) => {
            datadogLogs.logger.error(
              detail.message,
              detail.context,
              detail.error,
            );
          },
        );
      })
      .catch((e) => {
        console.error('cannot mount header', e);
      });
  }

  importSidebarNav() {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ myPageSidebarNav }) => {
        myPageSidebarNav.mount('my-page-sidebar-nav', {
          isJobLawyer: this.userService.lboxUser.lawyer,
        });
      })
      .catch((e) => {
        console.error('cannot mount my-page-sidebar-nav', e);
      });
  }

  importFooter() {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ footer }) => {
        footer.mount('footer');
      })
      .catch((e) => {
        console.error('cannot mount footer', e);
      });
  }

  importMembershipNudgingModal() {
    if (this.isServer) {
      return;
    }

    this.userService.userUpdated.subscribe(() => {
      // @ts-ignore
      import('@lbox-kr/components')
        .then(({ membershipNudgingModal }) => {
          console.log('membershipNudgingModal mount');
          membershipNudgingModal.mount('modal-root', {
            isUnauthorizedLawSchoolStudent:
              this.userService.isUnauthorizedLawSchoolStudent,
            haveUsedFreePeriod: !this.userService.hasMembershipChance,
            isLoggedIn: this.userService.logged,
            realCaseCount:
              this.userService.caseCountListData?.content[0]?.realCount ?? 0,
            onClickLawSchoolAuthorization: () => {
              void this.userService.authorizeLawyerStudent({
                channel: '사건 등록하기',
              });
            },
          });

          window.addEventListener(
            'membership-nudging-modal-event',
            (
              event: CustomEvent<{
                eventType: string;
                eventProperties?: Record<string, unknown>;
              }>,
            ) => {
              console.log('membership-nudging-modal-event 수신');
              void this.trackingService.tracking(
                event.detail.eventType,
                event.detail.eventProperties,
              );
            },
          );
        })
        .catch((e) => {
          console.error('cannot mount membership-nudging-modal', e);
        });
    });
  }

  importJobCardSelectionModal() {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    import('@lbox-kr/components')
      .then(({ jobCardSelectionModal }) => {
        jobCardSelectionModal.mount('modal-root');

        window.addEventListener('patch-lbox-user-job-success', () => {
          void this.userService.updateLboxUser();
        });
      })
      .catch((e) => {
        console.error('cannot mount job-card-selection-modal', e);
      });
  }

  mountToolbar(props: any): Promise<VoidFunction> {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    return import('@lbox-kr/components')
      .then(({ toolbar }) => {
        return toolbar.mount('toolbar-root', props);
      })
      .catch((e) => {
        console.error('cannot mount toolbar', e);
      });
  }
}
